<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new email dispatch</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new email dispatch</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onFormSubmit">
                            <!-- <template v-if="website">
                                <p>This form will be linked to the website
                                    <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: website.id } }">{{ website.label }}</router-link>.
                                    You can update the website content from this form.</p>
                            </template> -->
                            <!-- <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on">What is a form alias? <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text text-lighten-2"/></p>
                                </template>
                                <p>When you create a form, we generate a unique identifier for it. When a form is attached to a server, it is generally accessible at <span class="text-no-wrap">/mnt/form/&lt;id&gt;</span>. When you access the storage form via file server, we create a convenient link from your home directory to the form mount point at <span class="text-no-wrap">~/&lt;account-alias&gt;/&lt;form-alias&gt;</span>. This makes it easier to recognize and access forms that you have access to. A form alias must be unique within the account.</p>
                            </v-tooltip>
                            <v-text-field
                                ref="formAliasInput"
                                v-model="newFormAlias"
                                label="Form alias"
                                :rules="newFormAliasRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                hint="The directory name to use for the form (you can change this later)"
                                type="text"
                                outlined
                            >
                            </v-text-field> -->
                            <p>
                                A "dispatch" is the communication of a specific message (email template merged with data) to a specific audience (all selected contacts in an email audience). You can send the dispatch immediately or schedule it for a later date and time.
                            </p>
                            <!-- TODO: require dispatch template to be a verified template already added to the account -->
                            <p>
                                Before the dispatch can be processed:
                            </p>
                            <ul>
                                <li>The template must be approved</li>
                                <li>The template name(s) used in the "from" and "reply-to" addresses must be verified</li>
                                <li>An outbound email gateway must be configured</li>
                            </ul>
                            <p>
                                To improve deliverability:
                            </p>
                            <ul>
                                <li>Configure SPF in DNS</li>
                                <li>Configure DKIM in DNS and outbound email gateway</li>
                                <li>Configure DMARC in DNS and outbound email gateway</li>
                                <li>Check that email gateway outbound IP address is not on spam-block lists</li>
                            </ul>
                            <!-- TODO: should we ask user for mailbox and template input separately? then we can show list of templates they have; or if we ask for entire email address, we need to parse it to highlight if the template they entered is in their account or not... -->

                                <v-select outlined dense :items="newEmailDispatchTemplateChoices" v-model="newEmailDispatchTemplate" color="teal darken-2" label="Email template" required hint="The message you want to communicate">
                                    <!-- <template #append-outer>
                                        <v-btn icon color="teal darken-2" @click="onClickAddTemplate">
                                            <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                        </v-btn>
                                    </template> -->
                                </v-select>
                                <!-- <div class="mb-3"><p>@</p></div> -->
                                <v-select outlined dense :items="newEmailDispatchAudienceChoices" v-model="newEmailDispatchAudience" color="teal darken-2" label="Email audience" required hint="The audience you want to receive the message">
                                    <!-- <template #append-outer>
                                        <v-btn icon color="teal darken-2" @click="onClickAddTemplate">
                                            <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                        </v-btn>
                                    </template> -->
                                </v-select>

                            <!-- <p class="mb-6">
                                Don't see the template you want to use?
                                <router-link :to="{ name: 'account-create-template', params: { accountId: this.$route.params.accountId } }">Add a template...</router-link>
                            </p> -->

                            <!-- TODO: send now or schedule -->
                            <v-radio-group v-model="dispatchImmediately">
                            <v-radio label="Send immediately" :value="true"></v-radio>
                            <v-radio label="Schedule a future delivery" :value="false"></v-radio>
                            </v-radio-group>
                            <template v-if="!dispatchImmediately">
                                <!-- TODO: show today's date as a default, with pencil icon button to edit; tapping edit button should open dialog with date picker -->
                                <v-date-picker v-model="dispatchStartDate"></v-date-picker>
                                <!-- TODO: checkbox if user wants to pick a time? they also need to pick a timezone, OR we specify pacific or UTC .... also edit icon button to open dialog with time picker -->
                                <!-- <v-time-picker v-model="dispatchStartTime"></v-time-picker> -->
                            </template>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onFormSubmit" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
p {
    margin-bottom: 6px !important;
}
ul {
    margin-bottom: 6px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        templateList: [],
        audienceList: [],

        error: null,
        newEmailDispatchMailbox: null,
        newEmailDispatchMailboxRules: [
            (v) => !!v || 'Mailbox is required',
        ],
        newEmailDispatchTemplate: null,
        newEmailDispatchTemplateChoices: [], // will be loaded from server
        newEmailDispatchAudience: null,
        newEmailDispatchAudienceChoices: [], // will be loaded from server

        dispatchImmediately: true,
        dispatchStartDate: null, // only when dispatchImmediately is false
        dispatchStartTime: null, // only when dispatchImmediately is false
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return this.newEmailDispatchTemplate && this.newEmailDispatchAudience && (this.dispatchImmediately || this.dispatchStartDate);
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        dispatchStartOn() {
            const parts = [];
            if (this.dispatchStartDate) {
                parts.push(this.dispatchStartDate);
            }
            if (this.dispatchStartTime) {
                parts.push(this.dispatchStartTime);
            }
            const iso8601 = parts.join('T');
            const dateObject = new Date(iso8601);
            return dateObject.getTime();
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadTemplateList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTemplateList: true });
                const response = await this.$client.account(this.$route.params.accountId).emailTemplate.search();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.templateList = response.list;
                    this.newEmailDispatchTemplateChoices = response.list.map((item) => ({
                        text: item.label,
                        value: item.id,
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load template list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTemplateList: false });
            }
        },
        async loadAudienceList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAudienceList: true });
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.search();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.audienceList = response.list;
                    this.newEmailDispatchAudienceChoices = response.list.map((item) => ({
                        text: item.label,
                        value: item.id,
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load audience list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAudienceList: false });
            }
        },
        onFormSubmit() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEmailDispatch();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createEmailDispatch() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEmailDispatch: true });
                console.log('createEmailDispatch');
                const request = {
                    email_template_id: this.newEmailDispatchTemplate,
                    target_type: 'email_audience',
                    target_id: this.newEmailDispatchAudience,
                    not_before: this.dispatchImmediately ? Date.now() : this.dispatchStartOn, // TODO: need timezone; currently this is doing it in GMT/UTC, but this could be dependent on the browser .... we should show a time zone selector so it's explicit. then compute UTC time and send that to server.
                    // TODO: duplicate_guard (default true)
                    // TODO: duplicate_guard_window (default one day)
                };
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.create(request);
                console.log('createEmailDispatch response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-email-dispatch', params: { accountId: this.$route.params.accountId, emailDispatchId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email dispatch' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email dispatch' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create email dispatch', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email dispatch' });
            } finally {
                this.$store.commit('loading', { createEmailDispatch: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadAudienceList();
        this.loadTemplateList();
        // if (this.$route.query.website_id) {
        //     this.loadWebsite(this.$route.query.website_id);
        // }
    },
};
</script>
